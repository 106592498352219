import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "hello",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#hello",
        "aria-label": "hello permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hello`}</h1>
    <p>{`Hey 👋 I'm Colin.`}</p>
    <p>{`Full stack web developer 👨‍💻 | creator ✨ | maker 🚀`}</p>
    <p>{`These are my side projects 💡`}</p>
    <h2 {...{
      "id": "latest-projects-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#latest-projects-",
        "aria-label": "latest projects  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Latest projects 🔥`}</h2>
    <h3 {...{
      "id": "poly-effects-beebo---enabling-guitarix-lv2-plugins",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#poly-effects-beebo---enabling-guitarix-lv2-plugins",
        "aria-label": "poly effects beebo   enabling guitarix lv2 plugins permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Poly Effects Beebo - Enabling Guitarix LV2 plugins`}</h3>
    <p>
  Beebo comes bundled with some of the Guitarix LV2 plugins, here are the steps to enable them.
    </p>
    <p><a parentName="p" {...{
        "href": "/projects/poly-effects-beebo-guitarix-lv2-plugins"
      }}>{`Read more`}</a></p>
    <h3 {...{
      "id": "estimation-poker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#estimation-poker",
        "aria-label": "estimation poker permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Estimation Poker`}</h3>
    <p><a parentName="p" {...{
        "href": "/projects/estimation-poker"
      }}><img parentName="a" {...{
          "src": "/ep/estimationpoker-og.png",
          "alt": "Estimation Poker"
        }}></img></a></p>
    <p>
  <a href="/projects/estimation-poker">Estimation Poker</a> - an online planning poker tool that allows teams to hold an estimation session remotely.
    </p>
    <p><a parentName="p" {...{
        "href": "/projects/estimation-poker"
      }}>{`Read more`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      